@use "sass:math";

$typography: (
    'header-1': (
        'font-family': var(--font-family-custom-heading),
        'font-weight': var(--font-weight-bold, 700),
        'font-size-mobile-min': 50px,
        'font-size-mobile-max': 56px,
        'line-height-mobile': 0.85,
        'font-size-desktop-min': 52px,
        'font-size-desktop-max': 100px,
        'line-height-desktop': 0.85,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'header-2': (
        'font-family': var(--font-family-custom-heading),
        'font-weight': var(--font-weight-bold, 700),
        'font-size-mobile-min': 43px,
        'font-size-mobile-max': 48px,
        'line-height-mobile': 0.85,
        'font-size-desktop-min': 48px,
        'font-size-desktop-max': 84px,
        'line-height-desktop': 0.85,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'header-3': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-regular, 400),
        'font-size-mobile-min': 25px,
        'font-size-mobile-max': 28px,
        'line-height-mobile': 1,
        'font-size-desktop-min': 28px,
        'font-size-desktop-max': 36px,
        'line-height-desktop': 1.1,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'p-1': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-regular, 400),
        'font-size-mobile-min': 16px,
        'font-size-mobile-max': 16px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 16px,
        'font-size-desktop-max': 20px,
        'line-height-desktop': 1.1,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'p-2': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-regular, 400),
        'font-size-mobile-min': 14px,
        'font-size-mobile-max': 14px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 14px,
        'font-size-desktop-max': 18px,
        'line-height-desktop': 1.3,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'p-3': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-regular, 400),
        'font-size-mobile-min': 12px,
        'font-size-mobile-max': 12px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 12px,
        'font-size-desktop-max': 14px,
        'line-height-desktop': 1.2,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'action-small': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-medium, 500),
        'font-size-mobile-min': 12px,
        'font-size-mobile-max': 12px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 14px,
        'font-size-desktop-max': 14px,
        'line-height-desktop': 1.2,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'action-medium': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-medium, 500),
        'font-size-mobile-min': 14px,
        'font-size-mobile-max': 14px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 18px,
        'font-size-desktop-max': 18px,
        'line-height-desktop': 1.2,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
    'action-large': (
        'font-family': var(--font-family-custom-default),
        'font-weight': var(--font-weight-medium, 500),
        'font-size-mobile-min': 16px,
        'font-size-mobile-max': 16px,
        'line-height-mobile': 1.2,
        'font-size-desktop-min': 20px,
        'font-size-desktop-max': 20px,
        'line-height-desktop': 1.3,
        'breakpoint-desktop-min': 640px,
        'breakpoint-desktop-max': 1200px
    ),
);

$mobileBottomBreakpoint: 320px;

// Implementation for responsive typography is taken from
// https://css-tricks.com/linearly-scale-font-size-with-css-clamp-based-on-the-viewport/#for-those-who-dont-mind-that-edge-case
@function responsive-typo ($breakpointMin, $breakpointMax, $fontSizeMin, $fontSizeMax) {
    $slope: math.div($fontSizeMax - $fontSizeMin, $breakpointMax - $breakpointMin);
    $yAxisIntersection: -1 * $breakpointMin * $slope + $fontSizeMin;

    // --resize-speed allows to increase speed of recize in case when we need the text to be resized more quick
    // i.e. in case of multiple cards
    @return clamp(#{$fontSizeMin}, calc((#{$yAxisIntersection} + #{$slope * 100}vw) * var(--resize-speed, 1)), #{$fontSizeMax});
}

// this mixin isn't used anywhere but can be an idea for further typography improvements
// it would be nice to have responsive typography with possibility to pass exact sizes in rare cases
// boolean native-variable --is-responsive can be used for toggling responsiveness / static behaviour
//@mixin responsive-typo-native($breakpointMin, $desktopTopBreakpoint) {
//    --slope: calc((var(--font-size-max) - var(--font-size-min)) / (var(--breakpoint-max) - var(--breakpoint-min)));
//    --axis-intersection: calc(-1px * var(--breakpoint-max) * var(--slope));
//
//    font-size: clamp(
//        calc(var(--font-size-min, var(--font-size)) * 1px),
//        var(--axis-intersection) + calc(var(--slope) * 100vw),
//        calc(var(--font-size-max, var(--font-size)) * 1px)
//    );
//}

@mixin apply-responsive-font-size($key, $isResponsive: false) {
    $props: map-get($typography, $key);
    $desktopBottomBreakpoint: map-get($props, breakpoint-desktop-min);
    $desktopTopBreakpoint: map-get($props, breakpoint-desktop-max);
    $fontSizeMobileMin: map-get($props, font-size-mobile-min);
    $fontSizeMobileMax: map-get($props, font-size-mobile-max);
    $fontSizeDesktopMin: map-get($props, font-size-desktop-min);
    $fontSizeDesktopMax: map-get($props, font-size-desktop-max);

    font-weight: map-get($props, font-weight);
    font-size: var(--font-size, #{map-get($props, font-size-mobile-min)});
    line-height: var(--line-height, #{map-get($props, line-height-mobile)});
    font-family: map-get($props, font-family);

    // Fix for Headers in columns, when one letter drops to last line
    @include apply-screen-size(desktop-md) {
        --resize-speed: 0.8;
    }

    @include apply-screen-size(tablet-xlg) {
        --resize-speed: 0.9;
    }

    @include apply-screen-size(desktop-lg) {
        --resize-speed: 1;
    }
    // END of Fix

    @media (min-width: $desktopBottomBreakpoint + 1px) {
        --line-height: #{map-get($props, line-height-desktop)};
    }

    @if $isResponsive {
        --font-size: #{responsive-typo($mobileBottomBreakpoint, $desktopBottomBreakpoint, $fontSizeMobileMin, $fontSizeMobileMax)};

        @media (min-width: $desktopBottomBreakpoint + 1px) {
            --font-size: #{responsive-typo($desktopBottomBreakpoint, $desktopTopBreakpoint, $fontSizeDesktopMin, $fontSizeDesktopMax)};
        }
    }

    @else {
        @media (min-width: $desktopBottomBreakpoint + 1px) {
            --font-size: #{$fontSizeDesktopMax};
        }
    }
}
@mixin apply-typography-header-1($isResponsive: false) {
    @include apply-responsive-font-size('header-1', $isResponsive);

    text-transform: uppercase;
}
@mixin apply-typography-header-2($isResponsive: false) {
    @include apply-responsive-font-size('header-2', $isResponsive);

    text-transform: uppercase;
}
@mixin apply-typography-header-3($isResponsive: false) {
    @include apply-responsive-font-size('header-3', $isResponsive);
}
@mixin apply-typography-p-1($isResponsive: false) {
    @include apply-responsive-font-size('p-1', $isResponsive);
}
@mixin apply-typography-p-2($isResponsive: false) {
    @include apply-responsive-font-size('p-2', $isResponsive);
}
@mixin apply-typography-p-3($isResponsive: false) {
    @include apply-responsive-font-size('p-3', $isResponsive);
}
@mixin apply-typography-action-small($isResponsive: false) {
    @include apply-responsive-font-size('action-small', $isResponsive);
}
@mixin apply-typography-action-medium($isResponsive: false) {
    @include apply-responsive-font-size('action-medium', $isResponsive);
}
@mixin apply-typography-action-large($isResponsive: false) {
    @include apply-responsive-font-size('action-large', $isResponsive);
}

%apply-typography-header-1-responsive {
    @include apply-responsive-font-size('header-1', true);

    text-transform: uppercase;
}
%apply-typography-header-2-responsive {
    @include apply-responsive-font-size('header-2', true);

    text-transform: uppercase;
}
%apply-typography-header-3-responsive {
    @include apply-responsive-font-size('header-3', true);
}
%apply-typography-p-1-responsive {
    @include apply-responsive-font-size('p-1', true);
}
%apply-typography-p-2-responsive {
    @include apply-responsive-font-size('p-2', true);
}
%apply-typography-p-3-responsive {
    @include apply-responsive-font-size('p-3', true);
}
%apply-typography-action-small-responsive {
    @include apply-responsive-font-size('action-small', true);
}
%apply-typography-action-medium-responsive {
    @include apply-responsive-font-size('action-medium', true);
}
%apply-typography-action-large-responsive {
    @include apply-responsive-font-size('action-large', true);
}

%apply-text-pallete {
    color: var(--text-color, var(--color-text-default));
}

%apply-text-inherited {
    &.is-inherited {
        font-size: inherit;
    }

    p + p {
        margin-top: 1em;
    }
}

%apply-language-based-font-compatibility {
    [lang="fi"] &,
    [lang="nb"] &,
    [lang="fr"] &,
    [lang="de"] & {
        --line-height: 1;
        hyphens: auto;

        // Font Fix for Firefox, Opera
        margin-block-start: 0.3em;
    }

    [lang="sv"] &,
    [lang="nl"] &,
    [lang="pl"] & {
        --line-height: 1;

        // Font Fix for Firefox, Opera
        margin-block-start: 0.3em;
    }

    [lang="it"] &,
    [lang="ko"] &,
    [lang="ja"] &,
    [lang="zh-Hant"] &,
    [lang="zh"] &,
    [lang="pt-pt"] &,
    [lang="pt"] &,
    [lang="hi"] &,
    [lang="fr"] &,
    [lang="fr-ca"] &,
    [lang="es-mx"] &,
    [lang="es-ar"] &,
    [lang="es-co"] &,
    [lang="es"] & {
        --line-height: 1.1;

        // Font Fix for Firefox, Opera
        margin-block-start: 0.3em;
    }

    [lang="en-au"] &,
    [lang="en-ca"] &,
    [lang="en-in"] &,
    [lang="en-us"] &,
    [lang="en"] & {
        // Font Fix for Firefox, Opera
        margin-block-start: 0.3em;
    }

    [lang="ru"] & {
        --line-height: 1;
    }

    /**
    *
    * Fix for cases, when element is ins different from main content language
    *
    */
    &[lang="en"] {
        --line-height: inherit;
        hyphens: auto;
    }

    /**
    *
    * Fix for cases, when element has incorrect hypens due to white-space
    *
    */
    [lang="zh-Hant"] &,
    [lang="zh"] & {
        white-space: pre-wrap;
    }
}
