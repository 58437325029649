.columns {
    --columns-gap-vertical: var(--content-section-gap-vertical, 16px);
    --columns-gap-horizontal: var(--content-section-gap-horizontal, 16px);
    --columns-item-one-third-width: calc((100% - 2 * var(--columns-gap-horizontal)) / 3);

    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    gap: var(--columns-gap-vertical) var(--columns-gap-horizontal);
}

.columns__item {
    display: flex;
    width: 100%;
    word-wrap: break-word;
    min-width: 0;

    > * {
        min-width: 0;
    }
}

.columns__item--one-third {
    flex: 0 0 var(--columns-item-one-third-width);
}

.columns__item--half {
    flex: 0 0 calc((100% - var(--columns-gap-horizontal)) / 2);

    // Optical alignment for cases, when header fit in 50% of page width container
    h2.cta-box__header,
    .cta-box__header .header-2 {
        --resize-speed: 0.7;

        /**
        *
        * Fix for cases, when element has incorrect hypens due to white-space
        *
        */
        [lang="zh-Hant"] &,
        [lang="zh"] & {
            --resize-speed: 0.6;
        }
        [lang="sv"] & {
            --font-size: clamp(44px, (-2.85px + 8.57vw) * var(--resize-speed, 1), 100px);
            --resize-speed: 0.4;

            @include apply-screen-size(tablet-xlg) {
                --resize-speed: 0.46;
            }
            @include apply-screen-size(desktop-xlg) {
                --font-size: 70px;
            }
        }
    }
}

.columns__item--two-third {
    flex: 0 1 calc(var(--columns-item-one-third-width) * 2 + var(--columns-gap-horizontal));
}

.columns__item--center {
    display: flex;
    justify-content: center;
    text-align: center;
}

.columns--align-top {
    align-items: flex-start;
}

.columns--align-bottom {
    align-items: flex-end;
}

.columns__item--within {
    overflow: hidden;
}

.columns__item--align-end {
    justify-content: flex-end;
}

.columns__item--valign-stretch {
    align-self: stretch;
}

.columns--mobile {
    flex-direction: column;

    @include apply-screen-size(desktop-md) {
        flex-direction: row;
    }
}

.columns--mobile-flexible {
    .columns__item {
        flex-shrink: 1;
    }
}

.columns--mobile-inverted {
    .columns__item {
        flex: 0 1 100%;
        width: 100%;
    }

    @include apply-screen-size(desktop-md) {
        display: flex;
        flex-direction: row-reverse;
    }
}

.columns--equal {
    .columns__item {
        @include apply-screen-size(desktop-md) {
            flex: 0 1 50%;
            width: 50%;
        }
    }
}

.columns--fluid {
    .columns__item {
        flex-basis: auto;
        width: auto;
    }

    .columns__item + .columns__item {
        margin-left: auto;
    }
}

.section-cta {
    @include apply-screen-size(desktop-md) {
        .columns__item:first-child {
            flex: 0 0 50%;
            width: 50%;
        }

        .columns__item:last-child {
            flex: 1 1 auto;
        }

        .columns__item:last-child {
            padding-left: 5%;
        }

        .columns--mobile-inverted .columns__item:last-child {
            padding-right: 5%;
            padding-left: 0
        }
    }
}
