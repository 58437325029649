.cta-box {
    --cta-box-spacing-title-text: 16px;
    --cta-box-spacing-actions: 16px;
    --cta-box-spacing-content: 32px;

    @include apply-screen-size(desktop-sm) {
        --cta-box-spacing-title-text: 24px;
        --cta-box-spacing-actions: 24px;
        --cta-box-spacing-content: 48px;
    }
}

.cta-box--align-start {
    text-align: left;
}

.cta-box--align-center {
    text-align: center;
}

.cta-box__header {
    @extend %apply-typography-header-2-responsive;
    @extend %apply-language-based-font-compatibility;

    @include apply-screen-size(desktop-sm) {
        @include apply-typography-header-1(true);

        .page__section--smaller-title & {
            --font-size: clamp(6px, (16px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
        }
    }
    @include apply-screen-size(desktop-md) {
        .page__section--smaller-title & {
            --font-size: clamp(6px, (6px + 1.57vw) * var(--resize-speed, 1), 100px);
            line-height: 1.1;
        }
    }

    [lang="ja"] & {
        --resize-speed: 0.55;
        --font-size: clamp(12px, (38px + 1.5625vw) * var(--resize-speed, 1), 48px);

        font-size: var(--font-size, 32px);

        @include apply-screen-size(desktop-sm) {
            --font-size: clamp(32px, (5.715px + 7.86vw) * var(--resize-speed, 1), 100px);
        }

        @include apply-screen-size(desktop-xlg) {
            font-size: 72px;
        }
    }

    /**
      * When we have title in column that is 50%, we decrease font-size by 50%
      */
    [lang="ja"] .columns__item--half & {
        --font-size: calc(var(--font-size) / 2);
    }

    margin: 0 0 var(--cta-box-spacing-title-text);
    text-wrap: balance;

    &:last-child {
        margin-bottom: 0;
    }
}

h1.cta-box__header {
    /**
    *
    * Fix for cases, when element has incorrect hypens due to white-space
    *
    */
    [lang="zh-Hant"] &,
    [lang="zh"] & {
        --resize-speed: 0.6;
    }
}

.cta-box__text {
    @extend %apply-typography-p-2-responsive;

    @include apply-screen-size(desktop-sm) {
        @include apply-typography-p-1(true);
    }

    margin: 0 auto;

    & > a,
    button {
        @extend %a11y-button-reset;

        display: inline;
        color: inherit;
        text-decoration: underline;
    }

    a {
        display: inline;
        color: inherit;
        text-decoration: underline;
    }
}

.cta-box__bullet-point {
    margin-top: 20px
}

.cta-box__content {
    margin-top: var(--cta-box-spacing-content);

    .cta-box--align-center & {
        display: flex;
        justify-content: center;
    }
}

.cta-box__content--width-content {
    max-width: 480px;
    margin-right: auto;
    margin-left: auto;

}

.cta-box__content--neutral,
.cta-box__content:first-child {
    margin-top: 0;
}

.cta-box__actions {
    margin: var(--cta-box-spacing-actions) auto 0;
}

.cta-box__actions--center {
    align-items: center;
}

.cta-box__actions:first-child {
    margin-top: 0;
}
